import api from "@/core/services/api.service.js";
api.setHeader();
var isAlert = false;

const CrudModule = {
  async setSelectOption(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({ text: value.name, value: value.id });
        });
      })
      .catch((error) => {
        console.log("error", errorget);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setDoctorSelectOption(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({ text: value.name, value: value.name });
        });
      })
      .catch((error) => {
        console.log("error", errorget);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setTreeSelect(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({ label: value.name, id: value.id });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setTreeSelectProvince(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({ label: value.name, id: value.id, code_id: value.code_id });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setPaymentServiceTreeSelect(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value, index) => {
          arr.push({
            label: value.name,
            id: index,
            realValue: value.id,
            refTable: value.ref_table,
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setTreeSelectMedicalAction(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({ label: value.name, id: value.name });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setDoctorTreeSelect(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({
            label: `${value.name} - ${value.polyclinic_name}`,
            id: value.id,
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setTreeSelectWebsitePost(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({ label: value.name, id: value.id, isDisabled: true });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setDashboardList(route) {
    let arr = [],
      state = "";
    await api
      .get(route)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({
            time: value.start_time,
            desc:
              value.doctor_name +
              " - " +
              value.action_type_name +
              " " +
              value.display_patient_name,
            badge: "fa fa-genderless text-success",
            bold: true,
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async setTodaySchedule(route) {
    let arr = [],
      state = "";
    await api
      .get(route)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({
            title: value.doctor_name,
            desc: value.policlinic_name,
            phone: value.mobile_phone,
            svg: "/media/svg/icons/Communication/Group-chat.svg",
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  // Payment Page
  async paymentCategoriesTreeselect(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({
            label: value.name,
            id: value.id,
            price: value.price,
            const_price: value.price,
            discount_percentage: 0,
            discount_amount: 0,
            discount_type: 0,
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async medicinesTreeSelect(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({
            label: value.name,
            id: value.id,
            price: value.sales_price,
            stock: value.stock,
            quantity: 1,
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },

  async labProceduresTreeSelect(route) {
    let arr = [],
      state = "";
    await api
      .get(route, `?page_size=300`)
      .then((response) => {
        console.log("response", response);
        state = "success";
        response.data.data.forEach((value) => {
          arr.push({
            label: value.name,
            id: value.id,
            price: value.price,
            const_price: value.price,
            discount_percentage: 0,
            discount_amount: 0,
            discount_type: 0,
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return {
      state: state,
      data: arr,
    };
  },
  // End Payment Page

  async submit(data, route) {
    let state = "",
      validationError = "",
      success = "";
    // SwalLoading
    SwalLoading.fire();
    await api
      .post(route, data)
      .then((response) => {
        console.log("response ===================================", response);

        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else if (response.status == 500) {
          Swal.fire(
            "Gagal",
            "Terjadi Kesalahan Pada Sistem, Silahkan Hubungi Tim Wellmagic",
            "error"
          );
        } else {
          state = "success";
          success = response.data;
        }
      })
      .catch((error) => {
        console.log("error ===================================", error);
        console.log("error ========", error.response);
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        }
        if (error.response.status == 500) {
          let errors
          state = "error";
          error.response.data.errors = {
            example_column: [""]
          };
          validationError = error.response.data
          console.log('validationError', validationError)
        }
      })
      .finally(() => {
        // Close SwalLoading
        SwalLoading.close();
      });
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },
  async submitWithoutAlert(data, route) {
    let state = "",
      validationError = "",
      success = "";
    // SwalLoading
    // SwalLoading.fire();
    await api
      .post(route, data)
      .then((response) => {

        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          // Swal.fire(
          //   "Gagal",
          //   "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
          //   "error"
          // );
        } else if (response.status == 500) {
          // Swal.fire(
          //   "Gagal",
          //   "Terjadi Kesalahan Pada Sistem, Silahkan Hubungi Tim Wellmagic",
          //   "error"
          // );
        } else {
          state = "success";
          success = response.data;
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        }
      })
      .finally(() => {
        // Close SwalLoading
        // SwalLoading.close();
      });
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },
  async post(data, route) {
    await api
      .post(route, data)
      .then((response) => {
        console.log("response", response);
        state = "success";
        success = response;
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        }
      })
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },

  async submitDonor(data, route) {
    let state = "",
      validationError = "",
      success = "";
    // SwalLoading
    SwalLoading.fire();
    await api
      .post(route, data)
      .then((response) => {
        console.log("response", response);
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          state = "success";
          success = response.data;
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        }
      })
      .finally(() => {
        // Close SwalLoading
        SwalLoading.close();
      });
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },

  async list(route, slug = "") {
    let data = {};
    await api
      .get(route, slug)
      .then((response) => {
        console.log("response", response);
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1;
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async listCoa(route, slug = "") {
    let data = {};
    await api
      .get(route, slug)
      .then((response) => {
        console.log("response", response);
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
          for (let a = 0; a < data.length; a++) {
            data[a].list_number = a + 1;
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async paginate(route, slug = "") {
    let data = {},
      meta = {};
    await api
      .get(route, slug)
      .then((response) => {
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1;
          }
          meta = response.data.meta;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return { data: data, meta: meta };
  },


  async paginateWithoutNumber(route, slug = "") {
    let data = {},
      meta = {};
    await api
      .get(route, slug)
      .then((response) => {
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
          meta = response.data.meta;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return { data: data, meta: meta };
  },

  async getNoPgination(route, slug = "") {
    let data = {},
      meta = {};
    await api
      .get(route, slug)
      .then((response) => {
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return { data: data };
  },

  async paginateCoa(route, slug = "") {
    let data = {},
      meta = {};
    await api
      .get(route, slug)
      .then((response) => {
        console.log("response", response);
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
          for (let a = 0; a < data.length; a++) {
            data[a].list_number = a + 1;
          }
          meta = response.data.meta;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return { data: data, meta: meta };
  },

  async pagination(route, slug = "") {
    let data = {};
    await api
      .get(route, slug)
      .then((response) => {
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data;
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1;
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async get(route, params = "") {
    let data = null;
    await api
      .get(route, params)
      .then((response) => {
        // If Data Exist
        if (response.status != 204 && response.status != 401) {
          data = response.data.data;
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1;
          }
        } else if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async getMedicalActionReport(route, params = "") {
    let data = null;
    await api
      .get(route, params)
      .then((response) => {
        // If Data Exist
        if (response.status != 204 && response.status != 401) {
          data = response.data;
        } else if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async getDistributionGraphic(route, params = "") {
    let data = null;
    await api
      .get(route, params)
      .then((response) => {
        // If Data Exist
        if (response.status != 204 && response.status != 401) {
          data = response.data;
        } else if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async getAccessByUser(route, params = "") {
    let data = null;
    await api
      .get(route, params)
      .then((response) => {
        console.log("response", response);
        // If Data Exist
        if (response.status != 204 && response.status != 401) {
          data = response.data;
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1;
          }
        } else if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async delete(route) {
    let result = await Swal.fire({
      title: "Hapus Data?",
      text: "Data yang dihapus tidak bisa dikembalikan",
      icon: "question",
      showCancelButton: true,
    }).then(async function (result) {
      if (result.value) {
        // SwalLoading
        SwalLoading.fire();
        // Delete Data
        return await api
          .delete(route)
          .then((response) => {
            console.log("response", response);
            if (response.status == 401 && isAlert == false) {
              isAlert = true;
              Swal.fire(
                "Gagal",
                "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
                "error"
              );
            } else {
              // Success SweetAlert
              Swal.fire(response.data.title, response.data.message, "success");
              return true;
            }
          })
          .catch((error) => {
            console.log("error", error);
            // Close SwalLoading
            SwalLoading.close();
            return false;
          });
      }
    });
    return result;
  },

  async deleteMedicine(route, data) {
    let result = await Swal.fire({
      title: "Hapus Data?",
      text: "Data yang dihapus tidak bisa dikembalikan",
      icon: "question",
      showCancelButton: true,
    }).then(async function (result) {
      if (result.value) {
        // SwalLoading
        SwalLoading.fire();
        // Delete Data
        return await api
          .delete(route, data)
          .then((response) => {
            console.log("response", response);
            if (response.status == 401 && isAlert == false) {
              isAlert = true;
              Swal.fire(
                "Gagal",
                "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
                "error"
              );
            } else {
              // Success SweetAlert
              Swal.fire(response.data.title, response.data.message, "success");
              return true;
            }
          })
          .catch((error) => {
            console.log("error", error);
            // Close SwalLoading
            SwalLoading.close();
            return false;
          });
      }
    });
    return result;
  },
};

export default CrudModule;
